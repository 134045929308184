import React from "react";
import { Link } from "react-scroll";
import PATHNAMES from "../../constants/PATHNAMES";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge
} from "reactstrap";

class Header extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  state = {
    collapseClasses: "",
    collapseOpen: false,
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
    });
  };

  closeNavOnClick() {
    if (window.screen.width <= 999) {
      document.getElementById("navbar_global").click();
    }
  }

  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand
                className="mr-lg-5"
                to="main"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                tag={Link}
                href="/"
              >
                <img
                  alt="white-logo"
                  style={{ height: 55, width: 160 }}
                  src={require("../../assets/img/brand/white-logo.png")}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link
                        to="main"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        href="/"
                      >
                        <img
                          alt="blue-logo"
                          src={require("../../assets/img/brand/blue-logo.png")}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  <NavItem>
                    <NavLink
                      to="about-me"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      tag={Link}
                      href="/"
                      onClick={this.closeNavOnClick}
                    >
                      <i className="fa fa-info-circle d-lg-none mr-1" />
                      <span className="nav-link-inner--text">About</span>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      to="my-services"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      tag={Link}
                      href="/"
                      onClick={this.closeNavOnClick}
                    >
                      <i className="fa fa-server d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Services</span>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      to="why-me"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      tag={Link}
                      href="/"
                      onClick={this.closeNavOnClick}
                    >
                      <i className="fa fa-question-circle d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Why Me?</span>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      to="my-work"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      tag={Link}
                      href="/"
                      onClick={this.closeNavOnClick}
                    >
                      <i className="fa fa-code-fork d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Work</span>
                    </NavLink>
                  </NavItem>

                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="fa fa-heart d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Know Me</span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">
                        <Media
                          className="d-flex align-items-center"
                          href={PATHNAMES.PHOTOGRAPHY}
                          target="_blank"
                        >
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="fa fa-camera" />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-success mb-md-1">
                              Photography
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              Besides coding, this is the one thing which I love
                              to do in the me-time.
                            </p>
                          </Media>
                        </Media>
                        <Media
                          className="d-flex align-items-center"
                          href={PATHNAMES.ACHIEVEMENTS}
                          target="_blank"
                        >
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                            <i className="ni ni-trophy" />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              Achievements
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              For the sake of continuous betterment, I used to
                              participate in competitions.
                            </p>
                          </Media>
                        </Media>
                        <Media
                          className="d-flex align-items-center"
                          href={PATHNAMES.TRAVELLING}
                        >
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <i className="ni ni-spaceship" />
                          </div>
                          <Media body className="ml-3">
                            <h5 className="heading text-warning mb-md-1">
                              Travelling
                            </h5>
                            <p className="description d-none d-md-inline-block mb-0">
                              I like to explore new places, check out the
                              articles I wrote about the places I explored till
                              now.
                            </p>
                            <Badge color="default" pill className="mr-1">
                              paused due to covid19 
                            </Badge>
                          </Media>
                        </Media>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://www.facebook.com/iamvbj"
                      id="tooltip_fb"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-facebook-square" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Facebook
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip_fb">
                      See me on Facebook
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://twitter.com/iamvbj_"
                      id="tooltip_tw"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-twitter-square" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Twitter
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip_tw">
                      Follow me on Twitter
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://www.linkedin.com/in/iamvbj/"
                      id="tooltip_in"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-linkedin-square" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        LinkedIn
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip_in">
                      Check me on LinkedIn
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://gitlab.com/iamvbj"
                      id="tooltip_glab"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-gitlab" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Gitlab
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip_glab">
                      Star me on Gitlab
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem className="Sd-lg-block ml-lg-4">
                    <Button
                      className="btn-neutral btn-icon"
                      to="contact-me"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      tag={Link}
                      href="/"
                    >
                      <span className="btn-inner--icon">
                        $<i className="ni ni-money-coins mr-2" />
                      </span>
                      <span className="nav-link-inner--text ml-1">Hire Me</span>
                    </Button>
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default Header;
