import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/scss/argon-design-system-react.scss?v1.1.0";

import Home from "./pages/Home.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Suspense fallback={<div />}>
        <Route path="/" exact render={(props) => <Home {...props} />} />
      </Suspense>
      {/* <Route
        path="/blog"
        exact
        render={props => <Blog {...props} />}
      /> */}
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
