import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
} from "reactstrap";

class HomeHero extends React.Component {
  state = {
    defaultModal: false,
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  render() {
    return (
      <>
        <div className="position-relative">
          <section className="section section-hero section-shaped bg-gradient-default">
            <Container className="shape-container d-flex align-items-center py-lg">
              <div className="col px-0">
                <Row className="align-items-center justify-content-center">
                  <Col className="text-center" lg="6">
                    <h1 className="display-1 mb-0 text-white">
                      Hello, I'm{" "}
                      <span
                        style={{ color: "#f5365c", display: "inline-block" }}
                      >
                        Vaibhav
                      </span>
                    </h1>
                    <p className="lead text-white">
                      A{" "}
                      <span style={{ color: "yellow" }}>
                        Full Stack Developer
                      </span>{" "}
                      based in Pune, India. Experienced in developing fully
                      responsive
                      <span style={{ color: "yellow" }}> Websites</span>,{" "}
                      <span
                        className="btn-tooltip"
                        id="tooltip_pwa"
                        style={{ color: "yellow" }}
                      >
                        PWA
                      </span>
                      s and{" "}
                      <span
                        style={{ color: "yellow", display: "inline-block" }}
                      >
                        Mobile Apps
                      </span>
                      .
                    </p>
                    <UncontrolledTooltip
                      delay={0}
                      placement="bottom"
                      target="tooltip_pwa"
                      trigger="hover focus"
                    >
                      Progressive Web App
                    </UncontrolledTooltip>
                    <div className="btn-wrapper mt-5">
                      <Button
                        className="btn-icon mb-3 mb-sm-2"
                        color="github"
                        onClick={() => this.toggleModal("notificationModal")}
                        size="lg"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-button-play" />
                        </span>
                        <span className="btn-inner--text">Watch video</span>
                      </Button>

                      <Col md="4">
                        <Modal
                          className="modal-dialog-centered modal-danger"
                          contentClassName="bg-gradient-danger"
                          isOpen={this.state.notificationModal}
                          toggle={() => this.toggleModal("notificationModal")}
                        >
                          <div className="modal-header">
                            <h6
                              className="modal-title"
                              id="modal-title-notification"
                            >
                              Happy to see you here!
                            </h6>
                            <button
                              aria-label="Close"
                              className="close"
                              data-dismiss="modal"
                              type="button"
                              onClick={() =>
                                this.toggleModal("notificationModal")
                              }
                            >
                              <span aria-hidden={true}>×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="py-3 text-center">
                              <i className="ni ni-image ni-3x" />
                              <h4 className="heading mt-4">Hey !</h4>
                              <p>
                                The video and blog page is still under
                                development :-) Everything else is ready, have a
                                look.
                              </p>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <Button
                              className="btn-white"
                              color="default"
                              type="button"
                              onClick={() =>
                                this.toggleModal("notificationModal")
                              }
                            >
                              Ok, Got it
                            </Button>
                            <Button
                              className="text-white ml-auto"
                              color="link"
                              data-dismiss="modal"
                              type="button"
                              onClick={() =>
                                this.toggleModal("notificationModal")
                              }
                            >
                              Close
                            </Button>
                          </div>
                        </Modal>
                      </Col>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default HomeHero;
