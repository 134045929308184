import React, { Suspense, lazy } from "react";
// core components
import Header from "../components/Headers/Header.js";
// index page sections
import HomeHero from "../components/Heros/HomeHero.js";

const AboutMeCarousel = lazy(() =>
  import("../components/Carousels/AboutMeCarousel.js")
);
const ServiceCards = lazy(() => import("../components/Cards/ServiceCards.js"));
const WhyMeSection = lazy(() =>
  import("../components/CustomSections/WhyMeSection.js")
);
const WorkSection = lazy(() =>
  import("../components/CustomSections/WorkSection.js")
);
const BlogCTASection = lazy(() =>
  import("../components/CustomSections/BlogCTASection.js")
);
const KnowMeCarousel = lazy(() =>
  import("../components/Carousels/KnowMeCarousel.js")
);
const ContactMeForm = lazy(() =>
  import("../components/Forms/ContactMeForm.js")
);
const Footer = lazy(() => import("../components/Footers/Footer.js"));

class Home extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Header />
        <main id="main" ref="main">
          <HomeHero id="home-hero" />
          <section id="about-me" className="section">
            <Suspense fallback={<div />}>
              <AboutMeCarousel />
            </Suspense>
          </section>
          <section id="my-services" className="section bg-secondary">
            <Suspense fallback={<div />}>
              <ServiceCards />
            </Suspense>
          </section>
          <section id="why-me" className="section mt-4">
            <Suspense fallback={<div />}>
              <WhyMeSection />
            </Suspense>
          </section>
          <section
            id="my-work"
            className="section pb-8 mt-5 pt-6 bg-gradient-info"
          >
            <Suspense fallback={<div />}>
              <WorkSection />
            </Suspense>
          </section>
          <section
            id="blog-cta"
            className="section section-lg section-nucleo-icons pb-50"
          >
            <Suspense fallback={<div />}>
              <BlogCTASection />
            </Suspense>
          </section>
          <section id="know-me" className="section">
            <Suspense fallback={<div />}>
              <KnowMeCarousel />
            </Suspense>
          </section>
          <section id="contact-me" className="section">
            <Suspense fallback={<div />}>
              <ContactMeForm />
            </Suspense>
          </section>
        </main>
        <Suspense fallback={<div />}>
          <Footer />
        </Suspense>
      </>
    );
  }
}

export default Home;
